import { ISearchLayout, ISearchLayoutObject } from '../Model/ISearchLayout';
export function getSearchLayout(config): ISearchLayout {
  const layout = {
    callRelatedFields: [],
    screenPopsOpenWithin: null,
    objects: [],
    matchTypes: []
  };

  if (config && config.SearchLayout) {
    if (
      config.SearchLayout.variables &&
      config.SearchLayout.variables.callRelatedFields &&
      config.SearchLayout.variables.callRelatedFields.length > 0
    ) {
      layout.callRelatedFields =
        config.SearchLayout.variables.callRelatedFields;
    }

    if (config.SearchLayout.Entities) {
      for (const entity of Object.values(config.SearchLayout.Entities)) {
        if (entity['variables'] && entity['variables'].APIName) {
          const object: ISearchLayoutObject = {
            objectName: entity['variables'].APIName,
            objectFields: [],
            phoneFields: []
          };
          layout.objects.push(object);

          if (entity['DisplayFields'] && entity['DisplayFields']['variables']) {
            const displayFields = entity['DisplayFields']['variables'];
            for (const displayKey of Object.keys(displayFields)) {
              object.objectFields.push({
                DisplayName: displayFields[displayKey],
                APIName: displayKey
              });
            }
          }

          if (entity['variables'].DefaultSearchFields) {
            const defaultSearchFields = entity['variables'].DefaultSearchFields;
            for (const field of defaultSearchFields) {
              object.phoneFields.push({
                DisplayName: null,
                APIName: field
              });
            }
          }
        } else if (entity['variables']) {
          return null;
        }
      }
    }

    if (config.SearchLayout.matchTypes) {
      for (const type of Object.values(config.SearchLayout.matchTypes)) {
        if (
          type['variables'] &&
          type['variables'].screenPopType &&
          type['variables'].screenPopData
        ) {
          layout.matchTypes.push({
            screenPopType: type['variables'].screenPopType,
            screenPopData: type['variables'].screenPopData
          });
        }
      }
    }
  }
  return layout;
}
