import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { SectionContainerBodyDirective } from '../section-container-body.directive';
import { SectionContainerHeaderDirective } from '../section-container-header.directive';

@Component({
  selector: 'app-section-container',
  templateUrl: './section-container.component.html',
  styleUrls: ['./section-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionContainerComponent {
  @ContentChild(SectionContainerBodyDirective, { static: true })
  bodyDirective: SectionContainerBodyDirective;

  @ContentChild(SectionContainerHeaderDirective, { static: true })
  headerDirective: SectionContainerHeaderDirective;

  @Output() changed = new EventEmitter<number>();
  isCollapsed = false;

  get bodyTpl(): TemplateRef<any> {
    return this.bodyDirective && this.bodyDirective.tpl;
  }

  get headerTpl(): TemplateRef<any> {
    return this.headerDirective && this.headerDirective.tpl;
  }

  protected toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
