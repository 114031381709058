import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { getSearchLayout } from '../util/SearchLayout';
import { getClickToDialLayout } from '../util/ClickToDialLayout';
import { ICrmEntity } from '../Model/ICrmEntity';
import { bind } from 'bind-decorator';
import {
  Application,
  getCadPopKeys
} from '@amc-technology/applicationangularframework';
import { IActivity } from '../Model/IActivity';
import { IActivityDetails } from '../Model/IActivityDetails';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';
import { IClickToDialLayout } from '../Model/IClickToDialLayout';
import { ISearchLayout, ISearchLayoutObject } from '../Model/ISearchLayout';
import { getCadDisplayConfig } from '../util/DisplayCADInfo';
import { ICadDisplayConfig } from '../Model/ICadDisplayConfig';
import {
  CHANNEL_TYPES,
  IContextualContact,
  IRecordItem,
  RecordItem,
  SearchRecords
} from '@amc-technology/davinci-api';
import { IOracleContact } from '../bridge/IOracleContact';

@Component({
  selector: 'app-home',
  templateUrl: 'home-oraclecx.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends Application implements OnInit {
  private maxRecordsDefault = 50;
  public searchLayout: ISearchLayout;
  private lastSearchedCTDNumber: string;
  private cadDisplayConfig: ICadDisplayConfig;
  private clickToDialLayout: IClickToDialLayout;
  private contextualContacts: { [key: string]: IContextualContact } = {};
  private phoneNumberFormat: Object;
  public quickCommentList: string[];
  protected enableAutoSave: boolean;
  public enableCallActivity: boolean;
  public activityLayout: any;
  private lastClickToDialRecords: api.SearchRecords;
  private lastClickToDialIActivityDetails: IActivityDetails = null;
  private screenpopOnAlert: boolean;
  protected cadActivityMap: Object;
  public clickToDialPhoneReformatMap: Object;
  public DisplayQuickCreate: boolean;
  protected QuickCreateEntities: any;
  public quickCommentOptionRequiredCadArray: any;
  public searchRecordList: {
    [scenarioId: string]: IRecordItem[];
  };

  public clickToDialContacts: IOracleContact[];

  constructor(
    private loggerService: LoggerService,
    public storageService: StorageService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super(loggerService.logger);
    this.storageService.syncWithLocalStorage();
    this.phoneNumberFormat = {};
    this.lastClickToDialRecords = null;
    this.screenpopOnAlert = true;
    this.lastSearchedCTDNumber = '';
    this.clickToDialPhoneReformatMap = {};
    this.enableCallActivity = true;
    this.enableAutoSave = true;
    this.cadActivityMap = {};
    this.quickCommentOptionRequiredCadArray = {};
    this.searchRecordList = this.storageService.searchRecordList;
  }

  async ngOnInit() {
    try {
      this.logger.logDebug(
        'OracleCX - Home : START : Fetching OracleCX App Configuration'
      );
      await this.loadConfig();
      this.bridgeScripts = this.bridgeScripts.concat([this.getBridgeURL()]);

      await super.ngOnInit();
      this.getActivityLayout();
      this.bridgeEventsService.sendEvent(
        'updateActivityLayout',
        this.activityLayout
      );
      this.bridgeEventsService.sendEvent(
        'setObjects',
        this.searchLayout.objects
      );
      this.bridgeEventsService.subscribe(
        'clickToDialEntities',
        this.clickToDialEntitiesHandler
      );
      this.bridgeEventsService.subscribe(
        'clickToDial',
        this.clickToDialHandler
      );
      this.bridgeEventsService.subscribe(
        'createNewEntity',
        this.createNewEntity
      );
      this.bridgeEventsService.subscribe(
        'sendNotification',
        this.sendNotification
      );

      api.registerOnLogout(async () => {
        this.logger.logDebug(
          'Logging out, clearing local storage and pushing logs'
        );

        this.removeLocalStorageOnLogout();
        await this.loggerService.logger.pushLogsAsync();
      });
      this.logger.logDebug(
        'OracleCX - Home : Configuration from OracleCX App : ' +
          JSON.stringify(this.appConfig)
      );

      this.readConfig(this.appConfig);

      this.logger.logDebug(
        'OracleCX - Home : END : Fetching OracleCX App Configuration'
      );
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Fetching Configuration. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  private readConfig(config: api.IAppConfiguration) {
    try {
      this.logger.logDebug(
        'OracleCX - Home : START : Reading Configuration from OracleCX App'
      );
      const configPhoneFormat = config.variables['PhoneNumberFormat'];
      if (typeof configPhoneFormat === 'string') {
        const tempFormat = String(configPhoneFormat).toLowerCase();
        this.phoneNumberFormat[tempFormat] = tempFormat;
      } else {
        this.phoneNumberFormat = configPhoneFormat;
      }
      // this.quickCommentList = <string[]>(
      //   (config['CallActivity']
      //     ? config['CallActivity']['variables']['QuickComments']
      //     : config['variables']['QuickComments'])
      // );
      // this.cadActivityMap = config['CallActivity']
      //   ? config['CallActivity']['variables']['CADActivityMap']
      //   : config['variables']['CADActivityMap']
      //   ? config['variables']['CADActivityMap']
      //   : {};
      // for (let i = 0; i < this.quickCommentList.length; i++) {
      //   this.quickCommentList[i] = this.quickCommentList[i].replace(
      //     /\\n/g,
      //     String.fromCharCode(13, 10)
      //   );
      //   this.quickCommentList[i] = this.quickCommentList[i].replace(
      //     /\\t/g,
      //     String.fromCharCode(9)
      //   );
      // }
      // const cadQuickCommentRegex = /\{\{.*?\}\}/g;
      // for (let i = 0; i < this.quickCommentList.length; i++) {
      //   this.quickCommentOptionRequiredCadArray[i] = this.quickCommentList[
      //     i
      //   ].match(cadQuickCommentRegex);
      // }
      // if (config.variables['ClickToDialPhoneReformatMap']) {
      //   this.clickToDialPhoneReformatMap =
      //     config.variables['ClickToDialPhoneReformatMap'];
      // }
      // if (
      //   config['QuickCreate'] &&
      //   config['QuickCreate']['variables']['QuickCreateKeyList']
      // ) {
      //   this.QuickCreateEntities =
      //     config['QuickCreate']['variables']['QuickCreateKeyList'];
      //   this.DisplayQuickCreate =
      //     Object.keys(this.QuickCreateEntities).length > 0;
      // } else {
      this.DisplayQuickCreate = false;
      // }
      this.logger.logDebug(
        'OracleCX - Home : END : Reading Configuration from OracleCX App'
      );
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Reading Configuration. Config Info : ' +
          JSON.stringify(config) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected formatPhoneNumber(
    inputNumber: string,
    phoneNumberFormat: Object
  ): string {
    try {
      this.logger.logTrace(
        'OracleCX - Home : START : Formatting Phone Number. Input Number : ' +
          inputNumber +
          '. Configured Format : ' +
          JSON.stringify(phoneNumberFormat)
      );
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (
            (inputFormat.match(/x/g) || []).length !==
            (outputFormat.match(/x/g) || []).length
          ) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (
              inputFormat[j] !== '?' &&
              inputNumber[j] !== inputFormat[j]
            ) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber =
                  outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logTrace(
              'OracleCX - Home : END : Formatting Phone Number. Input Number : ' +
                inputNumber +
                '. Configured Format : ' +
                JSON.stringify(phoneNumberFormat) +
                '. Output Number : ' +
                outputNumber
            );
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Formatting Phone Number. Input Number : ' +
          inputNumber +
          '. Configured Format : ' +
          JSON.stringify(phoneNumberFormat) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.logger.logTrace(
      'OracleCX - Home : END : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat) +
        '. Output Number : ' +
        inputNumber
    );
    return inputNumber;
  }

  public checkIfRecentActivitiesExist() {
    try {
      return this.storageService.recentScenarioIdList.length > 0 ? true : false;
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Checking if Recent Activities Exist. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected removeLocalStorageOnLogout(reason?: string) {
    localStorage.clear();
  }

  // Sends Contact Details to eventmanager.js
  // Flow path: Home -> Bridge -> eventmanager.js
  protected async sendInteractionDetails(interaction: api.IInteraction) {
    try {
      const tempInteraction = {
        messageType: 'Interaction',
        channelType: api.CHANNEL_TYPES[interaction.channelType],
        state: api.INTERACTION_STATES[interaction.state],
        details: interaction.details,
        interactionId: interaction.interactionId,
        scenarioId: interaction.scenarioId,
        direction: api.INTERACTION_DIRECTION_TYPES[interaction.direction],
        userFocus: interaction.userFocus
      };

      const message = {
        sourceSystem: 'DaVinci',
        messageToSend: tempInteraction,
        timePublished: new Date().toLocaleTimeString()
      };

      const event = {
        contactDetails: message
      };

      const response = await this.bridgeEventsService.sendEvent(
        'sendContactDetailsInfo',
        event
      );
      this.logger.logDebug(
        'Oracle CX Service Cloud - Home : Received send Interaction Details response from bridge. Scenario ID : ' +
          interaction.scenarioId +
          ' ' +
          JSON.stringify(response)
      );
    } catch (error) {
      this.logger.logError(
        'Oracle CX Service Cloud - Home : ERROR : send Interaction Details . More Info : ' +
          JSON.stringify(error)
      );
    }
  }

  // eslint-disable-next-line max-statements
  protected async onInteraction(
    interaction: api.IInteraction
  ): Promise<api.SearchRecords> {
    this.logger.logDebug(
      'OracleCX - Home : Interaction recieved: ' + JSON.stringify(interaction)
    );
    this.logger.logInformation(
      'OracleCX - Home : Interaction recieved. Scenario ID : ' +
        interaction.scenarioId +
        ' . Interaction State : ' +
        interaction.state
    );
    try {
      const scenarioId = interaction.scenarioId;
      let isNewScenarioId = false;
      let isCTDNumber = false;
      let forceUpdate = false;
      this.storageService.updateCadFields(
        interaction,
        this.cadActivityMap,
        this.cadDisplayConfig
      );
      if (
        this.storageService.recentActivityListContains(scenarioId) &&
        this.storageService.currentScenarioId !== scenarioId
      ) {
        this.saveActivity(scenarioId, true, this.enableAutoSave);
        return;
      }

      if (
        interaction.details &&
        interaction.details.fields &&
        interaction.details.fields.Phone &&
        interaction.details.fields.Phone.Value
      ) {
        interaction.details.fields.Phone.Value = this.formatPhoneNumber(
          interaction.details.fields.Phone.Value,
          this.phoneNumberFormat
        );
        isCTDNumber =
          interaction.details.fields.Phone.Value === this.lastSearchedCTDNumber;
      }

      isNewScenarioId = await this.processIfNewScenario(interaction);

      if (
        interaction['userFocus'] ||
        (this.storageService.activeScenarioIdList.length === 1 &&
          this.storageService.activeScenarioIdList.indexOf(scenarioId) >= 0)
      ) {
        this.storageService.setCurrentScenarioId(scenarioId);
        forceUpdate = true;
      }

      if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        if (isCTDNumber) {
          this.lastSearchedCTDNumber = '';
        }
        this.deleteExistingScenario(interaction);
      } else if (
        interaction.state === api.INTERACTION_STATES.Alerting ||
        interaction.state === api.INTERACTION_STATES.Connected
      ) {
        if (!this.storageService.searchRecordList[scenarioId]) {
          this.bridgeEventsService.sendEvent('expandSidePanel', true);
        }
        if (isCTDNumber) {
          this.updateClickToDialList(scenarioId);
        } else {
          const searchRecord = await this.searchAndScreenpop(
            interaction,
            isNewScenarioId
          );
          this.storageService.setsearchRecordList(
            searchRecord.toJSON(),
            scenarioId
          );
          this.logger.logDebug(
            'OracleCX - Home : END : Interaction recieved: ' +
              JSON.stringify(interaction)
          );
          return searchRecord;
        }
      }

      if (forceUpdate) {
        this.changeDetectorRef.detectChanges();
      }
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : On Interaction. More Info : ' +
          JSON.stringify(error)
      );
    } finally {
      this.sendInteractionDetails(interaction);
    }
    this.logger.logDebug(
      'OracleCX - Home : END : Interaction recieved: ' +
        JSON.stringify(interaction)
    );
    return;
  }

  protected shouldPreformScreenpop(
    interaction: api.IInteraction,
    isNewScenarioId: boolean
  ) {
    return (
      ((interaction.state === api.INTERACTION_STATES.Alerting &&
        this.screenpopOnAlert === true) ||
        (interaction.state === api.INTERACTION_STATES.Connected &&
          this.screenpopOnAlert === false)) &&
      Object.keys(this.scenarioInteractionMappings).filter(
        (id) => id !== interaction.scenarioId
      ).length === 0 && // Dont pop if there is another scenario in progress
      !this.scenarioInteractionMappings[interaction.scenarioId][
        interaction.interactionId
      ] && // has not screenpoped yet
      this.screenpopEnabled &&
      interaction.details !== undefined &&
      interaction.details !== null
    );
  }

  private async searchAndScreenpop(
    interaction: api.IInteraction,
    isNewScenarioId: boolean
  ) {
    if (this.shouldPreformScreenpop(interaction, isNewScenarioId)) {
      this.logger.logInformation(
        'OracleCX - Home : Screen pop on interaction. Scenario ID : ' +
          interaction.scenarioId
      );
      this.logger.logDebug(
        'OracleCX - Home : Screen pop on interaction. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      this.scenarioInteractionMappings[interaction.scenarioId][
        interaction.interactionId
      ] = false;
      const records = await this.preformScreenpop(interaction);
      this.logger.logDebug(
        'OracleCX - Home : Screen pop on interaction. Results : ' +
          JSON.stringify(records)
      );
      return records;
    } else {
      this.logger.logInformation(
        'OracleCX - Home : Search on interaction. Scenario ID : ' +
          interaction.scenarioId
      );
      this.logger.logDebug(
        'OracleCX - Home : Search on interaction. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      const event = this.generateEventForScreenpop(interaction);
      event['search'] = true;
      const screenpopResult = await this.bridgeEventsService.sendEvent(
        'search',
        event
      );
      const records = this.formatCrmResults(screenpopResult);
      this.logger.logDebug(
        'OracleCX - Home : Search on interaction. Results after formatting : ' +
          JSON.stringify(records)
      );
      return records;
    }
  }

  protected deleteExistingScenario(interaction: api.IInteraction): void {
    try {
      this.logger.logInformation(
        'OracleCX - Home : START : Removing Scenario ID : ' +
          interaction.scenarioId
      );
      this.lastClickToDialIActivityDetails = null;
      if (this.scenarioInteractionMappings[interaction.scenarioId]) {
        delete this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ];
        if (
          Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
            .length === 0
        ) {
          this.saveActivity(interaction.scenarioId, true, this.enableAutoSave);
          this.storageService.onInteractionDisconnect(
            interaction.scenarioId,
            this.enableAutoSave
          );
          delete this.scenarioInteractionMappings[interaction.scenarioId];
        }
      }
      this.logger.logInformation(
        'OracleCX - Home : END : Removing Scenario ID : ' +
          interaction.scenarioId
      );
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Deleting existing Scenario. Scenario ID : ' +
          interaction.scenarioId +
          '. Interaction Info : ' +
          JSON.stringify(interaction) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async processIfNewScenario(
    interaction: api.IInteraction
  ): Promise<boolean> {
    try {
      this.logger.logTrace(
        'OracleCX - Home : START : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      if (
        !this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId)
      ) {
        this.scenarioInteractionMappings[interaction.scenarioId] = {};
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = false;
        if (
          this.storageService.activeScenarioIdList.indexOf(
            interaction.scenarioId
          ) < 0
        ) {
          if (this.enableCallActivity) {
            this.storageService.addActivity(this.createActivity(interaction));
            await this.saveActivity(
              interaction.scenarioId,
              false,
              this.enableAutoSave
            );
          }
        }
        this.logger.logInformation(
          'OracleCX - Home : New Scenario with Scenario ID : ' +
            interaction.scenarioId
        );
        this.logger.logTrace(
          'OracleCX - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
            JSON.stringify(interaction)
        );
        return true;
      }
      this.logger.logTrace(
        'OracleCX - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      return false;
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected buildSubjectText(interaction: api.IInteraction) {
    this.logger.logTrace(
      'OracleCX - Home : START : Building Subject Text. Interaction Info : ' +
        JSON.stringify(interaction)
    );
    let subjectText = '';
    try {
      const channelType = api.CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          subjectText = `${channelType}[${fields.Email.Value}]`;
        } else if (fields.Phone) {
          subjectText = `${'Call'}[${fields.Phone.Value}]`;
        } else if (fields.FullName) {
          subjectText = `${channelType}[${fields.FullName.Value}]`;
        }
      }
      this.logger.logInformation(
        'OracleCX - Home : Subject text for Scenario ID : ' +
          interaction.scenarioId +
          ' is ' +
          subjectText
      );
      this.logger.logTrace(
        'OracleCX - Home : END : Building Subject Text. Interaction Info : ' +
          JSON.stringify(interaction)
      );
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Creating new activity. Scenario ID : ' +
          interaction.scenarioId +
          '. Interaction Info : ' +
          JSON.stringify(interaction) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    return subjectText;
  }

  protected formatCrmResults(contacts: IOracleContact[]): api.SearchRecords {
    const result = new SearchRecords();
    for (const contact of contacts) {
      let name: string;
      if (contact.firstName && contact.lastName) {
        name = contact.firstName + ' ' + contact.lastName;
      } else if (contact.firstName) {
        name = contact.firstName;
      } else if (contact.lastName) {
        name = contact.lastName;
      }

      let email: string;
      if (contact.emails.length > 0) {
        email = contact.emails[0];
      }

      let phone: string;
      if (contact.phones.length > 0) {
        phone = contact.phones[0].number;
      }

      const record = new RecordItem(contact.id, 'Contact', 'Contact');
      result.addSearchRecord(record);
      if (name) {
        record.setFullName('Name', 'Name', name);
      }
      if (phone) {
        record.setPhone(contact.phones[0].name, contact.phones[0].name, phone);
        if (contact.phones.length > 1) {
          for (let i = 1; i < contact.phones.length; i++) {
            record.setField(
              'Phone' + (i + 1),
              contact.phones[i].name,
              contact.phones[i].name,
              contact.phones[i].number
            );
          }
        }
      }
      if (email) {
        record.setEmail('Email', 'Email', email);
        if (contact.emails.length > 1) {
          for (let i = 1; i < contact.emails.length; i++) {
            record.setField(
              'Email' + (i + 1),
              'Email' + (i + 1),
              'Email' + (i + 1),
              contact.emails[i]
            );
          }
        }
      }
    }
    return result;
  }

  protected createActivity(interaction: api.IInteraction): IActivity {
    try {
      this.logger.logDebug(
        'OracleCX - Home : START : Creating new Activity. Scenario ID : ' +
          interaction.scenarioId
      );
      const date = new Date();
      const activity: IActivity = {
        WhoObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: ''
        },
        WhatObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: ''
        },
        PhoneNumber: '',
        Subject: this.buildSubjectText(interaction),
        ChannelType: api.CHANNEL_TYPES[interaction.channelType],
        CallDurationInSeconds: 0,
        DirectionCode: interaction.direction,
        Description: '',
        Status: 'Open',
        TimeStamp: date,
        ActivityId: '',
        ScenarioId: interaction.scenarioId,
        CadFields: {},
        IsActive: true,
        IsProcessing: false,
        IsUnSaved: false,
        IsRecentWorkItemLoading: false
      };
      const phoneField = interaction.details.fields.Phone;
      if (phoneField && phoneField.Value) {
        activity.PhoneNumber = phoneField.Value;
      }
      for (const key in this.cadActivityMap) {
        if (interaction.details.fields[key]) {
          if (!activity.CadFields) {
            activity.CadFields = {};
          }
          activity.CadFields[this.cadActivityMap[key]] =
            interaction.details.fields[key].Value;
        }
      }
      this.logger.logDebug(
        'OracleCX - Home : New activity Info : ' + JSON.stringify(activity)
      );
      this.logger.logDebug(
        'OracleCX - Home : END : Creating new Activity. Scenario ID : ' +
          interaction.scenarioId
      );
      return activity;
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Creating new activity. Scenario ID : ' +
          interaction.scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async saveActivity(
    scenarioId,
    isComplete = false,
    saveToCRM = true
  ): Promise<string> {
    return;
    try {
      this.logger.logInformation(
        'OracleCX  - Home : START : Saving Activity to CRM. Scenario ID : ' +
          scenarioId
      );
      let activity = this.storageService.getActivity(scenarioId);
      if (activity && activity.IsActive && isComplete) {
        activity.CallDurationInSeconds = this.getSecondsElapsed(
          activity.TimeStamp
        );
        activity.IsActive = false;
      }
      if (!activity || !saveToCRM) {
        return;
      }
      activity.Status = isComplete ? 'Completed' : 'Not Completed';
      this.logger.logDebug(
        'OracleCX - Home : Activity Info to be sent to bridge. Scenario ID : ' +
          scenarioId +
          '. Activity Info : ' +
          JSON.stringify(activity)
      );
      activity = await this.bridgeEventsService.sendEvent(
        'saveActivity',
        activity
      );
      this.logger.logDebug(
        'OracleCX - Home : Received Activity Info from bridge. Scenario ID : ' +
          scenarioId +
          '. Activity Info : ' +
          JSON.stringify(activity)
      );
      this.storageService.updateActivity(activity);
      this.storageService.activityList[scenarioId].IsProcessing = false;
      this.storageService.updateActivityFields(scenarioId);
      this.storageService.compareActivityFields(scenarioId);
      return Promise.resolve(activity.ActivityId);
    } catch (error) {
      this.storageService.activityList[scenarioId].IsProcessing = false;
      api.sendNotification(
        'Call activity save failed.',
        api.NOTIFICATION_TYPE.Error
      );
      this.logger.logError(
        'OracleCX - Home : ERROR : Saving Activity to CRM. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async getRecentWorkItem(scenarioId): Promise<void> {
    try {
      this.logger.logInformation(
        'OracleCX - Home : START : Recent Work Item Details from CRM. Scenario ID : ' +
          scenarioId
      );
      const activity = this.storageService.getActivity(scenarioId);
      const recentWorkItem = await this.bridgeEventsService.sendEvent(
        'getActivity',
        activity
      );
      this.storageService.updateRecentWorkItem(
        recentWorkItem,
        scenarioId,
        this.activityLayout
      );
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading =
        false;
      this.logger.logInformation(
        'OracleCX - Home : END : Recent Work Item Details from CRM. Scenario ID : ' +
          scenarioId
      );
    } catch (error) {
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading =
        false;
      api.sendNotification(
        'Error Retrieving Activity Details',
        api.NOTIFICATION_TYPE.Error
      );
      this.logger.logError(
        'OracleCX - Home : ERROR : Recent Work Item Details from CRM. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async getActivityLayout() {
    try {
      this.logger.logTrace(
        'OracleCX - Home : START : Fetching Activity Layout'
      );
      this.activityLayout = {};
      for (const item in CHANNEL_TYPES) {
        if (isNaN(Number(item))) {
          this.activityLayout[item] = {};
          this.activityLayout[item]['APIName'] = 'phonecall';
          this.activityLayout[item]['Fields'] = [
            '_regardingobjectid_value',
            'phonecall_activity_parties',
            'Subject',
            'Description'
          ];
          this.activityLayout[item]['LookupFields'] = {
            _regardingobjectid_value: 'WhatObject',
            phonecall_activity_parties: 'WhoObject'
          };
        }
      }
      this.logger.logDebug(
        'OracleCX - Home : Activity Layout information : ' +
          JSON.stringify(this.activityLayout)
      );
      this.logger.logTrace('OracleCX - Home : END : Fetching Activity Layout');
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Fetching Activity Layout. More information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected agentSelectedCallerInformation(object) {
    this.logger.logDebug(
      'OracleCX - Home : START : Agent Selected Entity to screenpop. Entity ID : ' +
        object
    );
    this.bridgeEventsService.sendEvent(
      'agentSelectedCallerInformation',
      object
    );
    this.logger.logDebug(
      'OracleCX - Home : END : Agent Selected Entity to screenpop. Entity ID : ' +
        object
    );
  }

  protected isToolbarVisible(): Promise<boolean> {
    throw new Error('Not Implemented!');
  }

  protected async getSearchLayout(): Promise<api.SearchLayouts> {
    throw new Error('Not Implemented!');
  }

  protected createNewEntity(type: string) {
    try {
      this.logger.logTrace(
        'OracleCX - Home : START : Quick Create Entity Type : ' + type
      );
      let param = {
        entityType: type
      };
      const activity = this.storageService.getActivity();
      if (activity) {
        if (activity.PhoneNumber) {
          param['phoneNumber'] = activity.PhoneNumber;
        }
        if (type === 'contact') {
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
        } else if (type === 'incident') {
          param['topic'] = activity.Subject;
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
          param = this.storageService.RetrieveEntityFromWhatList(
            'contact',
            param
          );
        } else if (type === 'lead') {
          param['topic'] = activity.Subject;
        } else if (type === 'opportunity') {
          param['topic'] = activity.Subject;
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
          param = this.storageService.RetrieveEntityFromWhatList(
            'contact',
            param
          );
        }
      }
      this.logger.logDebug(
        'OracleCX - Home : Quick create request to bridge with params : ' +
          JSON.stringify(param)
      );
      this.bridgeEventsService.sendEvent('createNewEntity', param);
      this.logger.logTrace(
        'OracleCX - Home : END : Quick Create Entity Type : ' + param
      );
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Quick Create. Entity Type : ' +
          type +
          '. More Info : ' +
          JSON.stringify(error)
      );
    }
  }

  protected onFocusHandler(entities: IOracleContact[]) {
    this.clickToDialContacts = entities;
    this.logger.logDebug('onFocusEvent START: ' + entities);
    api.clearContextualContacts();
    super.onFocusHandler(entities);
    this.logger.logDebug('onFocusEvent END');

    for (let i = 0; i < entities.length; i++) {
      const activityObject = this.buildActivityDetails(entities[i]);
      if (this.storageService.currentScenarioId) {
        this.storageService.updateWhoWhatLists(
          activityObject,
          this.storageService.currentScenarioId
        );
      }
      if (this.storageService.workingRecentScenarioId) {
        this.storageService.updateWhoWhatLists(
          activityObject,
          this.storageService.workingRecentScenarioId
        );
      }
    }
  }

  @bind
  protected sendNotification(event: any) {
    api.sendNotification(event.notification, event.notificationType);
  }

  @bind
  protected clickToDialHandler(event: any) {
    if (event.records) {
      const clickToDialRecord = event.records.entity
        ? JSON.stringify(event.records.entity.CRMinfos)
        : event.records;
      this.lastSearchedCTDNumber = event.number;
      const recordsList = clickToDialRecord;
      this.lastClickToDialRecords = this.formatCrmResults(recordsList);
      for (let i = 0; i < recordsList.length; i++) {
        this.lastClickToDialIActivityDetails = this.buildActivityDetails(
          recordsList[i]
        );
      }
      const numberToDial = this.clickToDialFormatPhoneNumber(event.number);
      api.clickToDial(numberToDial, this.lastClickToDialRecords);
    }
  }

  protected async processConfig(config: api.IAppConfiguration) {
    this.cadPopKeys = getCadPopKeys(
      config.SearchLayout &&
        config.SearchLayout.variables['InteractionSearchFields']
        ? String(config.SearchLayout.variables['InteractionSearchFields'])
        : ''
    );
    this.maxRecordsDefault = <number>config.variables.maxRecordsDefault;
    this.screenpopOnAlert =
      config.variables.screenpopOnAlerting != null
        ? <boolean>config.variables.screenpopOnAlerting
        : this.screenpopOnAlert;
    // if (
    //   config['CallActivity'] &&
    //   config['CallActivity']['variables'] &&
    //   config['CallActivity']['variables']['EnableCallActivity'] !== undefined
    // ) {
    //   this.enableCallActivity = <boolean>(
    //     config['CallActivity']['variables']['EnableCallActivity']
    //   );
    // }
    // if (
    //   config['CallActivity'] &&
    //   config['CallActivity']['variables'] &&
    //   config['CallActivity']['variables']['EnableAutoSave'] !== undefined
    // ) {
    //   this.enableAutoSave = <boolean>(
    //     config['CallActivity']['variables']['EnableAutoSave']
    //   );
    // }
    // this.storageService.maxRecentItems = <Number>(
    //   (config['CallActivity']
    //     ? config['CallActivity']['variables']['MaxRecentItems']
    //     : config['variables']['MaxRecentItems'])
    // );
    this.searchLayout = getSearchLayout(config);
    this.AddActivityToSearchLayout();
    // this.clickToDialLayout = getClickToDialLayout(config);
    this.cadDisplayConfig = getCadDisplayConfig(config);
    this.storageService.displayCadData = this.cadDisplayConfig.DisplayCad;
    this.bridgeEventsService.sendEvent('setLayouts', [
      this.searchLayout,
      this.clickToDialLayout
    ]);
  }

  protected AddActivityToSearchLayout() {
    const object: ISearchLayoutObject = {
      objectName: 'activity',
      objectFields: [],
      phoneFields: []
    };
    const activityDisplayFields = {};
    activityDisplayFields['phonecall_activity_parties'] =
      'phonecall_activity_parties';
    activityDisplayFields[
      '_regardingobjectid_value@Microsoft.OracleCX.CRM.lookuplogicalname'
    ] = 'regardingobjectid_value@Microsoft.OracleCX.CRM.lookuplogicalname';
    activityDisplayFields['_regardingobjectid_value'] =
      '_regardingobjectid_value';
    activityDisplayFields['description'] = 'Description';
    activityDisplayFields['subject'] = 'Subject';

    if (activityDisplayFields) {
      for (const displayKey of Object.keys(activityDisplayFields)) {
        object.objectFields.push({
          DisplayName: activityDisplayFields[displayKey],
          APIName: displayKey
        });
      }
    }
    this.searchLayout.objects.push(object);
  }

  protected async registerForAmcEvents() {
    await super.registerForAmcEvents();
    await api.registerContextualControls((event) =>
      api.clickToDial(event.uniqueId)
    );
    await api.registerSetSupportedChannels(
      async (appName, channels) =>
        await this.bridgeEventsService.sendEvent(
          'sendSupportedChannelsInfo',
          channels
        )
    );
  }

  protected getUserInfoHandler(): Promise<string> {
    return this.bridgeEventsService.sendEvent('getUserInfo', null);
  }

  protected buildActivityDetails(entityOBJ): IActivityDetails {
    const entity = entityOBJ;
    const activityObject: IActivityDetails = {
      objectType: '',
      displayName: '',
      objectName: '',
      objectId: '',
      url: ''
    };
    activityObject.objectType = entity.DisplayName;
    activityObject.objectId = entity.Id;
    const entityConfiguration = this.searchLayout.objects.filter(
      (e) => e.objectName === entity.DisplayName
    );
    if (
      entityConfiguration.length > 0 &&
      entityConfiguration[0].objectFields.length > 0 &&
      entityConfiguration[0].objectFields[0].APIName
    ) {
      const displayName = entityConfiguration[0].objectFields[0].APIName;
      activityObject.displayName = entity[displayName];
    }
    return activityObject;
  }

  protected getSecondsElapsed(startDate): number {
    try {
      this.logger.logLoop('OracleCX - Home : START : Get Seconds Elapsed');
      const endDate = new Date();
      if (typeof startDate === 'string') {
        startDate = new Date(startDate);
      }
      this.logger.logLoop('OracleCX - Home : END : Get Seconds Elapsed');
      return Math.round((endDate.getTime() - startDate.getTime()) / 1000);
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Get Seconds Elapsed. Start Date : ' +
          startDate +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  @bind
  protected clickToDialEntitiesHandler(entities) {
    this.logger.logDebug('clickToDialEntitiesHandler START: ' + entities);
    const contacts: { [key: string]: IContextualContact } = {};
    entities.forEach((entity) => {
      entity = entity.entity;
      for (const objectLayout of this.clickToDialLayout.Entities) {
        if (objectLayout.Name === entity.Type) {
          for (const field of objectLayout.PhoneFields) {
            if (entity[field.APIName]) {
              contacts[entity[field.APIName]] = {
                uniqueId: '' + Object.keys(contacts).length,
                firstName: entity[field.APIName],
                channels: []
              };
              break;
            }
          }
          break;
        }
      }
    });

    let isNew = false;
    for (const key in contacts) {
      if (
        !(
          this.contextualContacts[key] &&
          this.contextualContacts[key].uniqueId === contacts[key].uniqueId &&
          this.contextualContacts[key].firstName === contacts[key].firstName
        )
      ) {
        isNew = true;
        break;
      }
    }

    if (isNew) {
      this.contextualContacts = contacts;
      api.addContextualContacts(Object.values(contacts));
    }
    this.logger.logDebug('clickToDialEntitiesHandler END');
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(
      this.clickToDialPhoneReformatMap
    );
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat =
          this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (
          (inputFormat.match(/x/g) || []).length !==
          (outputFormat.match(/x/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/\(/g) || []).length !==
          (number.match(/\(/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/-/g) || []).length !==
          (number.match(/-/g) || []).length
        ) {
          continue;
        }
        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber =
                outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  protected updateClickToDialList(scenarioId: string): void {
    try {
      this.logger.logTrace(
        'OracleCX - Home : START : Update Click To Dial Who/What Lists. Scenario ID : ' +
          scenarioId
      );
      this.storageService.setsearchRecordList(
        this.lastClickToDialRecords.toJSON(),
        scenarioId
      );
      if (this.lastClickToDialIActivityDetails) {
        this.storageService.updateWhoWhatLists(
          this.lastClickToDialIActivityDetails,
          scenarioId
        );
      }
      this.logger.logTrace(
        'OracleCX - Home : END : Update Click To Dial Who/What Lists. Scenario ID : ' +
          scenarioId
      );
    } catch (error) {
      this.logger.logError(
        'OracleCX - Home : ERROR : Updating Click to Dial Who/What Lists. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  screenpop(record: IRecordItem) {
    this.bridgeEventsService.sendEvent(
      'agentSelectedCallerInformation',
      record
    );
  }

  @bind
  clickToDial(phoneNumber: string) {
    api.clickToDial(this.clickToDialFormatPhoneNumber(phoneNumber));
  }
}
