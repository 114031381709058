import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home-oraclecx.component';
import { StorageService } from './storage.service';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';
import { ActivityComponent } from './activity/activity.component';
import { CreateNewComponent } from './create-new/create-new.component';
import { CallerInformationComponent } from './caller-information/caller-information.component';
import { RecentactivitesComponent } from './recentactivites/recentactivites.component';
import { SectionContainerComponent } from './shared/section-container/section-container.component';
import { SectionContainerHeaderDirective } from './shared/section-container-header.directive';
import { SectionContainerBodyDirective } from './shared/section-container-body.directive';
import { ClickToDialComponent } from './click-to-dial/click-to-dial.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ActivityComponent,
    CreateNewComponent,
    CallerInformationComponent,
    RecentactivitesComponent,
    SectionContainerComponent,
    SectionContainerHeaderDirective,
    SectionContainerBodyDirective,
    ClickToDialComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigurationService, loggerService: LoggerService) =>
        async () => {
          await configService.loadConfigurationData();
          await loggerService.initialize();
        },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
    LoggerService,
    StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
