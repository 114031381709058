import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-create-new',
  templateUrl: './create-new.component.html',
  styleUrls: ['./create-new.component.css']
})
export class CreateNewComponent implements OnInit {
  collapseToggle: boolean;
  @Input() Entities: any;
  @Output() createNewEntity: EventEmitter<string> = new EventEmitter<string>();

  constructor(private loggerService: LoggerService) {
    this.collapseToggle = true;
  }

  ngOnInit() {}

  expandAndCollapse(size) {
    if (size === 'Expand') {
      this.collapseToggle = true;
    } else {
      this.collapseToggle = false;
    }
  }

  protected getDisplay(entity) {
    try {
      return entity.substring(0, entity.indexOf('|'));
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Create : ERROR : Create New Entity. Input :' +
          JSON.stringify(entity) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected getImage(entity) {
    try {
      return entity.substring(entity.indexOf('|') + 1);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Create : ERROR : Create New Entity. Input :' +
          JSON.stringify(entity) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  getEntities() {
    try {
      return Object.keys(this.Entities);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Create : ERROR : Create New Entity. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  quickCreate(createNewEntity) {
    try {
      this.createNewEntity.emit(createNewEntity);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Create : ERROR : Create New Entity. Input :' +
          createNewEntity +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }
}
