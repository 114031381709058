import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent {
  @Input() scenarioId: string;
  @Input() quickCommentList: string[];
  @Input() isAutoSave: boolean;
  @Input() quickCommentOptionRequiredCadArray: any;
  @Output() saveActivity: EventEmitter<string> = new EventEmitter<string>();

  collapseToggle: boolean;

  constructor(
    private loggerService: LoggerService,
    protected storageService: StorageService
  ) {
    this.collapseToggle = true;
  }

  protected expandAndCollapse(size: string) {
    if (size === 'Expand') {
      this.collapseToggle = true;
    } else {
      this.collapseToggle = false;
    }
  }

  protected loadText(comment: string) {
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : START : Load Quick Comments. Input : ' + comment
    );
    try {
      let descriptionToSet = this.quickCommentList[comment];
      if (this.quickCommentOptionRequiredCadArray[comment]) {
        // This means the option is configured to accept CAD Automatically
        // Loop through quickCommentOptionRequiredCadArray and replace {{cad}} with the cad coming from channel app
        let cadFields = {};
        if (this.storageService.activityList[this.scenarioId]) {
          cadFields = this.storageService.scenarioToCADMap[this.scenarioId];
        }
        for (
          let i = 0;
          i < this.quickCommentOptionRequiredCadArray[comment].length;
          i++
        ) {
          let keyToCheckIfCADExists = this.quickCommentOptionRequiredCadArray[
            comment
          ][i];
          const stringToBeReplaced = this.quickCommentOptionRequiredCadArray[
            comment
          ][i];
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('{{', '');
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('}}', '');
          if (cadFields[keyToCheckIfCADExists]) {
            descriptionToSet = descriptionToSet.replace(
              stringToBeReplaced,
              cadFields[keyToCheckIfCADExists].Value
            );
          }
        }
      }
      if (!this.storageService.getDescription()) {
        this.storageService.setDescription(
          this.quickCommentList[comment],
          this.scenarioId
        );
      } else {
        this.storageService.setDescription(
          this.storageService.getDescription() + '\n' + descriptionToSet,
          this.scenarioId
        );
      }
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Activity : ERROR : Load Quick Comments. Input : ' +
          comment +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : END : Load Quick Comments. Input : ' + comment
    );
  }

  protected isChangesUnSaved(scenarioId: string): boolean {
    const activity = this.storageService.getActivity();
    this.storageService.activityList[scenarioId].IsUnSaved =
      activity.IsUnSaved || (!activity.ActivityId && !this.isAutoSave);
    return this.storageService.activityList[scenarioId].IsUnSaved;
  }

  protected submitActivity(scenarioId: string) {
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : START : Submit Activity. Scenario ID : ' +
        scenarioId
    );
    try {
      this.storageService.activityList[scenarioId].IsProcessing = true;
      this.saveActivity.emit(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Activity : ERROR : Submit Activity. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : END : Submit Activity. Scenario ID : ' + scenarioId
    );
  }

  protected onNameSelectChange(event: any) {
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : START : On Name Change. Input : ' +
        JSON.stringify(event)
    );
    try {
      this.storageService.UpdateWhoObjectSelectionChange(
        event.currentTarget.value,
        this.scenarioId
      );
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Activity : ERROR : On Name Change. Input : ' +
          JSON.stringify(event) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : END : On Name Change. Input : ' +
        JSON.stringify(event)
    );
  }

  protected onRelatedToChange(event: any) {
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : START : On Related To Change. Input : ' +
        JSON.stringify(event)
    );
    try {
      this.storageService.UpdateWhatObjectSelectionChange(
        event.currentTarget.value,
        this.scenarioId
      );
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Activity : ERROR : On Related To Change. Input : ' +
          JSON.stringify(event) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : END : On Related To Change. Input : ' +
        JSON.stringify(event)
    );
  }

  protected onSubjectChange(event: any) {
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : START : On Subject Change. Input : ' +
        JSON.stringify(event)
    );
    try {
      if (
        event.type === 'keyup' &&
        this.storageService.activityList[this.scenarioId].IsUnSaved
      ) {
        return;
      }
      this.storageService.setSubject(event.srcElement.value, this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Activity : ERROR : On Subject Change. Input : ' +
          JSON.stringify(event) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : END : On Subject Change. Input : ' +
        JSON.stringify(event)
    );
  }

  protected onCallNotesChange(event: any) {
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : START : On Call Notes Change. Input : ' +
        JSON.stringify(event)
    );
    try {
      if (
        event.type === 'keyup' &&
        this.storageService.activityList[this.scenarioId].IsUnSaved
      ) {
        return;
      }
      this.storageService.setDescription(
        event.srcElement.value.trim(),
        this.scenarioId
      );
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'OracleCX - Activity : ERROR : On Call Notes Change. Input : ' +
          JSON.stringify(event) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace(
      'OracleCX - Activity : END : On Call Notes Change. Input : ' +
        JSON.stringify(event)
    );
  }
}
