import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IOracleContact } from '../bridge/IOracleContact';

@Component({
  selector: 'app-click-to-dial',
  templateUrl: './click-to-dial.component.html',
  styleUrls: ['./click-to-dial.component.css']
})
export class ClickToDialComponent {
  @Input() contacts: IOracleContact[];
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickToDial = new EventEmitter<string>();

  clickToDial(phoneNumber: string) {
    this.onClickToDial.emit(phoneNumber);
  }
}
